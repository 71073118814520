export const storageAdd = {
	warningProductAlreadyOnList: "Esse produto já foi adicionado na lista!",
	title: "Adicionar produto no estoque {{storageName}}",
	cancel: "Cancelar",
	finish: "Concluido",
	addProductForm: {
		form: {
			label: {
				product: "Produto",
				count: "Quantidade",
				cost: "Preço de custo",
			},
			validation: {
				missingField: "Campo obrigatorio",
				invalidQuantity: "Quantidade invalida",
			},
			submit: "Adicionar",
		},
	},
	table: {
		columns: {
			product: "Produto",
			count: "Quantidade",
			unitcost: "Custo unitário",
			totalcost: "Custo total",
			action: "ação",
		},
	},
};
