import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { EVENT_KEYS } from "./keys";
import enterprise from "#resources/api/enterprise-client";
import { EventList } from "#resources/api/enterprise-generated";
import { SdkgenError } from "@sdkgen/browser-runtime";
import { showErrorNotification } from "#resources/helpers/notifications";
import { EnterpriseApiSignature } from "#resources/helpers/api";

type GetEventsSignature = EnterpriseApiSignature<"getEvents">;
type UseGetEventsOptions = Omit<
	UseQueryOptions<GetEventsSignature["result"], SdkgenError>,
	"queryKey"
>;

export const useGetEvents = <TData = EventList>(
	params: GetEventsSignature["args"],
	options?: UseGetEventsOptions,
	select?: (data: EventList) => TData,
) => {
	const { placeId, month, year } = params;

	return useQuery({
		...options,
		queryKey: EVENT_KEYS.getEventsMonthYear(placeId, `${month}/${year}`),
		queryFn: () => enterprise.getEvents({ placeId, month, year }),
		throwOnError(error) {
			showErrorNotification(error.message);

			return false;
		},
		select,
	});
};

export const useHasOpenedEvent = (placeId: string) => {
	const month = new Date().getMonth();
	const year = new Date().getFullYear();

	return useGetEvents({ placeId, month, year }, {}, eventList => {
		const hasOpenedEvent = eventList.events.find(event => event.status === "Running");

		return Boolean(hasOpenedEvent);
	});
};
