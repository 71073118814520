export const allEventActionsReport = {
	title: "Ações do Evento",
	description: "Lista de todas as ações ao longo do evento.",
	filters: {
		employee: {
			label: "Nome de operador",
			placeholder: "Digite ou selecione o operador",
		},
		transaction: {
			label: "Tipo de transação",
			placeholder: "Digite ou selecione o tipo",
		},
	},
	columns: {
		id: "Id",
		date: "Data e hora",
		transactionType: "Tipo de transação",
		employee: "Operador",
		user: "Cliente",
		paymentMethod: "Método de pagamento",
		products: {
			title: "Produtos",
			name: "Nome do produto",
			count: "Quantidade",
			value: "Valor",
		},
		value: "Valor",
	},
	filename: "relatorio-acoes-do-evento",
	paymentMethodWithCashless: {
		ZigPosCredit: "ZigPos Crédito",
		ZigPosCreditInInstallments: "ZigPos Crédito em Parcelas",
		ZigPosDebit: "ZigPos Débito",
		CreditCard: "Cartão de crédito",
		DebitCard: "Cartão de débito",
		Cash: "Dinheiro",
		App: "App",
		Voucher: "Voucher",
		Anticipated: "Antecipado",
		Pix: "Pix",
		Cashless: "Cashless",
		IntegratedFoodVoucher: "Vale-refeição integrado",
		MBWay: "Mbway",
		Bonus: "Bônus",
		MinimumConsumption: "Consumo mínimo",
	},
};
