export const purchaseInvoice = {
	titlePage: "Purchase Invoices",
	description:
		"The purchase invoice is the document referring to the movement of the stock of goods that your company is receiving. Here you will be able to import your invoices, view your import history, as well as the product breakdown of them.",
	tabs: {
		historyNf: "Import history",
		detailingByProduct: "Detailing by Product",
	},
	importHistory: {
		columns: {
			nf: "NF",
			supplier: "Supplier",
			emission: "Emission Date",
			createdAt: "Import Date",
			totalValue: "Value",
			status: "Status",
			statusMessage: {
				processed: "Processed",
				Unprocessed: "Unprocessed",
				Deprocessed: "Deprocessed",
			},
		},
		fields: {
			searchNf: "Note number",
			searchSupplier: "Supplier Name",
			searchDateEmissionRange: {
				start: "Start Date Issue",
				end: "End Date Issue",
			},
			searchDateImportRange: {
				start: "Start Date Import",
				end: "End Import Date",
			},
		},
		table: {
			emptyText: "There are no notes to display",
			reportPrint: "Import history",
		},
		excelExportHistoryImport: {
			filename: "import_history",
			title: "Import history",
		},
		modalDelete: {
			title: "Delete Note",
			cancelText: "Cancel",
			okText: "Delete",
			message1: "Invoice deletion",
			message2: "It's permanent. Do you want to continue anyway?",
		},
		modalUnprocess: {
			title: "Unprocess Invoice",
			cancelText: "Cancel",
			closeText: "Close",
			okText: "Unprocess Invoice",
			message1: "When unprocessing a invoice you generate the following actions:",
			message2: "The products added to the stock will be removed;",
			message3: "The posting of the invoice will be removed from accounts payable.",
			message4: "Do you want to unprocess the invoice anyway?",
		},
		actionTable: {
			delete: "Delete",
			unprocess: "Unprocess",
			download: "Download",
		},
	},
	detailingProduct: {
		unassociatedProduct: "Unassociated product",
		columns: {
			nf: "NF",
			supplierName: "Supplier",
			emission: "Emission Date",
			unitQuantity: "QTD (NF)",
			commercialUnit: "UN (NF)",
			unitValue: "Unit Cost (NF)",
			totalValue: "Total cost (NF)",
			processedAt: "Inventory entry date",
			invoiceEntry: "Imported Product",
			associateProduct: "Associated product (ZIG)",
			unitQuantityZig: "QTD (ZIG)",
			commercialUnitZig: "UN (ZIG)",
			unitValueZig: "Unit cost (ZIG)",
		},
		excelExportDetailingProduct: {
			fileName: "detailing_product",
			title: "Detailing by product",
		},
		fields: {
			searchNf: "Enter the note number",
			searchSupplier: "Enter issuer name",
			searchDateRange: {
				processedAtDate: { start: "Start date entry", end: "End date entry" },
				emissionDate: { start: "Start date issue", end: "End date issue" },
			},
			isAssociatedProduct: "Unassociated products only",
		},
		emptyText: "There are no unassociated products in this tax profile",
	},
	importationInvoices: {
		title: "Select a Fiscal Profiles to get started",
		emptyText: "No Purchase Invoice",
		fiscalProfiles: "Select fiscal profile",
		issueDate: "Filter by date",
		issuer: "Filter by supplier name",
		status: "Filter by status",
		btnUpdate: "Update",
		xmlUnavailableInfo:
			"To make the XML files with unavailable status available, it is necessary to carry out the invoice manifestation. If you have any doubts about the manifestation, consult your accountant.",
		modalInfoImport: {
			notAllowed: {
				title: "Not allowed",
			},
			xmlUnavailable: {
				title: "XML unavailable",
				reason:
					"The XML code for this invoice is not yet available. Wait to import the invoice.",
			},
		},
		columns: {
			issuerName: "Issuer Name",
			issuerCnpj: "CNPJ or CPF",
			issueDate: "Issue Date",
			totalValue: "Total Value",
			status: {
				title: "Status",
				imported: "Imported",
				authorized: "Authorized",
				notAllowed: "Not Authorized",
				xmlUnavailable: "XML Unavailable",
			},
		},
	},
};
