import { IStepProps, Steps } from "#components/stepper";
import {
	BasePrechargeOperation,
	PrechargeOperationBonus,
} from "#resources/api/enterprise-generated";

export type PrechargeOperationRouteKey = keyof typeof PrechargeOperationRoutes;
export type PrechargeOperationRoute = typeof PrechargeOperationRoutes[PrechargeOperationRouteKey];

export const PrechargeOperationRoutes = {
	HOME: "precharge",
	FORM: "precharge/form",
	EDIT: "precharge/:prechargeOperationId/form",
} as const;

export type BonusSettingWithIndex = Omit<PrechargeOperationBonus, "type"> & {
	index?: number;
};

export type BonusSetting = Pick<PrechargeOperationBonus, "bonusValue" | "value">;

export interface PrechargeOperationFormValues
	extends Omit<BasePrechargeOperation, "placeId" | "bonus"> {
	logoBuffer: Buffer | null;
	logoUrl?: string | null;
	mainPageImageBuffer: Buffer | null;
	mainPageImageUrl?: string | null;
	preSetedValue: number;
	bonusSettingsDict: { [key: number]: number };
}

export const PrechargeOperationStepsEnum = {
	EVENT_DATA: "EVENT_DATA",
	CUSTOMIZATION_SETTINGS: "CUSTOMIZATION_SETTINGS",
	RECHARGE_SETTINGS: "RECHARGE_SETTINGS",
	TIPS: "TIPS",
} as const;

export type PrechargeOperationStepsKeys = keyof typeof PrechargeOperationStepsEnum;

export type PrechargeOperationExtraStepData = {
	[key in PrechargeOperationStepsKeys]: {
		icon: JSX.Element;
		validations: {
			key: GlobalTypes.ExtractKeys<PrechargeOperationFormValues>;
			isRequired: boolean;
			format?: {
				regexp: RegExp;
				message: string;
			}[];
		}[];
	};
};

export type PrechargeOperationSteps = Steps<
	PrechargeOperationStepsKeys,
	PrechargeOperationFormValues
>;

export type PrechargeOperationStepProps = IStepProps<
	PrechargeOperationFormValues,
	PrechargeOperationStepsKeys
>;
