import * as React from "react";
import { NotificationsContextData, NotificationsTabs } from "./types";
import {
	useListReadNotifications,
	useListUnreadNotifications,
	useSetReadNotifications,
} from "#resources/hooks/integrations/notifications";

const NotificationsContext = React.createContext({} as NotificationsContextData);

export const NotificationsProvider = ({ children }: { children?: React.ReactNode }) => {
	const [showNotifications, setShowNotifications] = React.useState<boolean>(false);

	const [currentTab, setCurrentTab] = React.useState<NotificationsTabs>("unread");

	const {
		data: unreadNotifications,
		refetch: refetchListUnreadNotifications,
		fetchNextPage: fetchNextUnreadNotificationsPage,
	} = useListUnreadNotifications();

	const {
		data: readNotifications,
		refetch: refetchListReadNotifications,
		fetchNextPage: fetchNextReadNotificationsPage,
	} = useListReadNotifications();
	const { mutateAsync: doSetReadNotifications } = useSetReadNotifications();

	const hasUnreadNotifications =
		unreadNotifications?.pages.some(p => p.data.length > 0) ?? false;

	async function listUnreadNotifications() {
		await refetchListUnreadNotifications();
	}

	async function listReadNotifications() {
		await refetchListReadNotifications();
	}

	async function setNotificationsAsRead(notificationsIds: string[]) {
		await doSetReadNotifications({
			dashboardNotificationIds: notificationsIds,
		});
	}

	async function onShowMoreUnreadNotifications() {
		if (unreadNotifications) {
			const { data: notifications } = await fetchNextUnreadNotificationsPage();

			if (!notifications) {
				return;
			}

			const hasNotifications = notifications.pages.some(p => p.data.length > 0);

			if (hasNotifications) {
				const ids: string[] = [];
				notifications.pages.forEach(p => {
					p.data.forEach(notification => {
						ids.push(notification.id);
					});
				});
				await setNotificationsAsRead(ids);
			}
		}
	}

	async function onShowMoreReadNotifications() {
		if (readNotifications) {
			fetchNextReadNotificationsPage();
		}
	}

	async function onOpenNotifications() {
		setShowNotifications(true);
		const { data: notifications } = await refetchListUnreadNotifications();

		if (!notifications) {
			return;
		}

		const hasNotifications = notifications.pages.some(p => p.data.length > 0);

		if (hasNotifications) {
			const ids: string[] = [];
			notifications.pages.forEach(p => {
				p.data.forEach(notification => {
					ids.push(notification.id);
				});
			});
			await setNotificationsAsRead(ids);
		}
	}

	function onCloseNotifications() {
		setShowNotifications(false);
		setCurrentTab("unread");
	}

	async function onChangeTabs(tab: NotificationsTabs) {
		setCurrentTab(tab);
		if (tab === "unread") {
			await listUnreadNotifications();
		}
		if (tab === "read") {
			await listReadNotifications();
		}
	}

	return (
		<NotificationsContext.Provider
			value={{
				unreadNotifications,
				readNotifications,
				showNotifications,
				currentTab,
				hasUnreadNotifications,
				onChangeTabs,
				onCloseNotifications,
				onOpenNotifications,
				onShowMoreReadNotifications,
				onShowMoreUnreadNotifications,
			}}
		>
			{children}
		</NotificationsContext.Provider>
	);
};

export function useNotifications() {
	return React.useContext(NotificationsContext);
}
