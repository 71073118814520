export const waiterReport = {
	tabTitle: "Vendas",
	title: "Vendas por funcionário",
	description:
		"Relatórios referentes as vendas dos bares, os operadores e ranking de produtos.",
	filter: "Filtrar por tipo de transação",
	transactionSell: "Vendas por transação",
	productSell: "Vendas por produto",
	waiterSell: "Vendas por garçom",
	filename: "vendas-por-garcom",
	filterWaiterLabel: "Nome do funcionário",
	filterWaiterPlaceholder: "Selecione o(s) funcionário(s)",
	deletedEmployee: "Usuário excluído",
	table: {
		name: "Nome",
		soldValue: "Valor vendido",
		tip: "Valor em serviço",
		total: "Total",
	},
	sellerTransactionTable: {
		date: "Data",
		clients: "Clientes",
		zigCode: "Número do cartão",
		products: "Produtos",
		refunded: "Estornado",
		transactionValue: "Valor da transação",
		tip: "Valor da gorjeta",
		filename: "vendas-por-transacao",
	},
	sellerProductTable: {
		name: "Nome",
		category: "Categoria",
		count: "Quantidade",
		value: "Valor",
		discount: "Desconto",
		total: "Total",
		filename: "vendas-por-produto",
	},
};
