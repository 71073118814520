import { globalKeysEN } from "./global-keys";
import { placeEN } from "./place";
import { orgEN } from "./org";
import { componentsEN } from "./components";
import { eventEN } from "./event";
import { navEN } from "./nav";
import { storeEN } from "./store";
import { noAuthEN } from "./no-auth";
import { backofficeEN } from "./backoffice";
import { sharedEN } from "./shared";

export const en_US = {
	globalKeys: globalKeysEN,
	place: placeEN,
	org: orgEN,
	components: componentsEN,
	event: eventEN,
	nav: navEN,
	store: storeEN,
	noAuth: noAuthEN,
	backoffice: backofficeEN,
	shared: sharedEN,
};
