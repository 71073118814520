export const financialReport = {
	label: "Fechamento",
	finished: {
		end: "Fechado: {{end}}",
		not: "O evento não foi fechado",
	},
	reportIn: "Faturamento do evento '{{event}}'",
	fileName: "faturamento-{{event}}",
	tabs: {
		bonusByProduct: {
			title: "Bônus por produto",
			filename: "bonus_por_produto",
			columns: {
				product: "Produto",
				category: "Categoria",
				value: "Bônus recebido no evento",
				bonusSpentFromOtherEvents: "Bônus recebido em outros eventos",
				total: "Total",
				totalCategory: "Total ({{category}})",
			},
			cards: {
				totalProductsValue: "Total de bônus em Produtos",
				totalTipValue: "Total de bônus em Serviço",
				totalValue: "Total",
				receivedInPeriod: "Recebido no evento",
				receivedAtAnotherPeriod: "Recebido em outros eventos",
			},
		},
		bonusReport: {
			title: "Bônus por cliente",
			filenameBonus: "bonus-por-cliente",
			filenameAnticipated: "consumacao-antecipada",
			filenameComsumption: "consumacao",
			total: "Total de {{category}}",
			columns: {
				client: "Cliente",
				date: "Data",
				reason: "Motivo",
				receivedValue: "Valor recebido",
				spentValue: "Valor gasto no evento",
				spentValueInOtherEvents: "Valor gasto em outros eventos",
				givenBy: "Dado por",
				total: "Total",
			},
		},
	},
};
