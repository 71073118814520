import NavBar, { NavBarRoute } from "#components/nav-bars-shared/nav-bar";
import { useSandwichMenu } from "#structure/providers/sandwich-menu";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavBar as NavBarV2 } from "#components/nav-bars-shared/nav-bar-v2";
import {
	NavBarRouteV2,
	RouteItemProps,
	RouteItemPropsV2,
	useNavigationWithRestriction,
} from "../navigation-with-permissions";
import { extractAlternativeUrlsFromTabs } from "../extract-from-tabs";
import {
	barReportsByEventTabs,
	financialReportsByEventTabs,
	operationsReportEventTabs,
} from "#resources/helpers/tabs";
import { useModularization } from "../use-modularization";
import { usePermission } from "#resources/hooks";
import { observer } from "mobx-react-lite";
import { useStores } from "#stores";

interface ComponentProps {
	placeId: string;
	eventId: string;
}

type Props = ComponentProps;

function Nav(props: Props) {
	const { placeStore } = useStores();
	const baseUrl = `/place/${props.placeId}/event/${props.eventId}`;
	const { t } = useTranslation("nav", { keyPrefix: "navbar.event" });
	const {
		isOpenSandwichMenu,
		onChangeIsOpenSandwichMenu,
		currentSelectedMenu,
	} = useSandwichMenu();

	const { hasPermissionGrouped } = usePermission();

	const hasRappi = placeStore.hasFeature("rappiPay");

	const tabsPermissions = hasPermissionGrouped([
		"seeBonusReports",
		"seeCashierReports",
		"seeFinanceReports",
		"seeOpenedBillsReport",
		"seeRefundReports",
		"seeProductsSoldReport",
		"seeSellsByWaiterReport",
		"seeBarReports",
		"seeBarConsolidatedSellingsReport",
	]);

	const operationsReportTabsUrlsList = extractAlternativeUrlsFromTabs(
		operationsReportEventTabs(props.placeId, props.eventId).filter(item => !item.hide),
		true,
	);

	const barReportsTabs = barReportsByEventTabs(
		props.placeId,
		props.eventId,
		tabsPermissions,
		{
			burnPaperTickets: placeStore.hasFeature("burnPaperTickets"),
			ficha: placeStore.hasFeature("ficha"),
		},
	);

	const barReports = extractAlternativeUrlsFromTabs(barReportsTabs, true);

	const { showMenuV2 } = useModularization();

	const routesV1: RouteItemProps[] = [
		{
			icon: "panel",
			label: t("panel"),
			url: `${baseUrl}`,
		},
		{
			icon: "file-check",
			label: t("conference"),
			url: `${baseUrl}/conference`,
			placeFeature: "backoffice",
		},
		{
			icon: "clients",
			label: t("clients"),
			url: `${baseUrl}/clients`,
		},
		{
			icon: "list-multiple",
			label: t("guestList"),
			url: `${baseUrl}/guest-lists`,
		},
		{
			icon: "financial-report",
			label: t("financialReport"),
			url: `${baseUrl}/reports`,
		},
		{
			icon: "bar-report",
			label: t("barReports"),
			url: `${baseUrl}/bar-reports`,
		},
		{
			icon: "table-report",
			label: t("tableReports"),
			url: `${baseUrl}/table-reports`,
			placeFeature: "hasMesa",
		},
		{
			icon: "tickets-report",
			label: t("ticketsReport"),
			url: `${baseUrl}/entrance-reports`,
		},
		{
			icon: "report",
			label: t("operationReport"),
			url: `${baseUrl}/operation-event-actions-report`,
		},
		{
			icon: "bag-icon",
			label: t("hawkersReport"),
			url: `${baseUrl}/hawkers-report`,
			placeFeature: "hawkerModule",
			permissions: ["seeHawkerReports"],
		},
		{
			icon: "tax",
			label: t("sellChip"),
			url: `${baseUrl}/sellChip`,
		},
		{
			icon: "bookings",
			label: t("bookings"),
			url: `${baseUrl}/reserve`,
		},
		{
			icon: "pdv-status",
			label: t("devices"),
			url: `${baseUrl}/device-status`,
		},
		{
			icon: "multivendor",
			label: t("multivendor"),
			url: `${baseUrl}/multivendor-closings`,
			orgFeaturesIds: "multivendor",
		},
		{
			icon: "zig-tag",
			label: t("zigTag"),
			url: `${baseUrl}/zig-tags`,
		},
		{
			icon: "recharge-management",
			label: t("rechargeManagement"),
			url: `${baseUrl}/recharge-management`,
			permissions: ["manageRecharge"],
		},
	];

	const routesV2: RouteItemPropsV2[] = [
		{
			icon: "business-intelligence-v2",
			label: t("eventPanel"),
			url: `${baseUrl}`,
		},
		{
			icon: "file-check",
			label: t("conference"),
			url: `${baseUrl}/conference`,
			placeFeature: "backoffice",
		},
		{
			icon: "receptive",
			label: t("guestList"),
			url: `${baseUrl}/guest-lists`,
			alternativeUrls: [`${baseUrl}/guest-lists-report`],
		},
		{
			icon: "crm",
			label: t("customers"),
			url: `${baseUrl}/clients`,
		},
		{
			icon: "reports",
			label: t("reports"),
			submenus: [
				{
					label: t("financialReport"),
					url: `${baseUrl}/reports`,
					alternativeUrls: extractAlternativeUrlsFromTabs(
						financialReportsByEventTabs(props.placeId, props.eventId, {
							...tabsPermissions,
							hasRappi,
						}),
					),
				},
				{
					label: t("barReports"),
					url: barReports[0],
					alternativeUrls: barReports,
				},
				{
					label: t("operationReport"),
					url: operationsReportTabsUrlsList[0],
					alternativeUrls: operationsReportTabsUrlsList,
				},
				{
					label: t("hawkersReport"),
					url: `${baseUrl}/hawkers-report`,
					alternativeUrls: [
						`${baseUrl}/hawkers-report#general`,
						`${baseUrl}/hawkers-report#summary`,
						`${baseUrl}/hawkers-report#ranking`,
						`${baseUrl}/hawkers-report#releases`,
						`${baseUrl}/hawkers-report#cashierClosing`,
					],
					placeFeature: "hawkerModule",
				},
				{
					label: t("ticketsReport"),
					url: `${baseUrl}/entrance-reports`,
					alternativeUrls: [
						`${baseUrl}/entrance-reports#/box-office-billing`,
						`${baseUrl}/entrance-reports#/sold-entrances`,
						`${baseUrl}/entrance-reports#/entrance-by-employee`,
					],
				},
				{
					label: t("tableReports"),
					url: `${baseUrl}/table-reports`,
					placeFeature: "hasMesa",
					alternativeUrls: [
						`${baseUrl}/table-reports#general`,
						`${baseUrl}/table-reports#table-products-sold`,
					],
				},
			],
		},
		{
			icon: "sell-with-no-card",
			label: t("sellChip"),
			url: `${baseUrl}/sellChip`,
		},
		{
			icon: "bookings",
			label: t("bookings"),
			url: `${baseUrl}/reserve`,
			alternativeUrls: [`${baseUrl}/reserveReport`],
		},
		{
			icon: "devices",
			label: t("devices"),
			url: `${baseUrl}/device-status`,
		},
		{
			icon: "multivendor-v2",
			label: t("multivendor"),
			url: `${baseUrl}/multivendor-closings`,
			alternativeUrls: [
				`${baseUrl}/multivendor-products-sold`,
				`${baseUrl}/multivendor-revenues`,
			],
			orgFeaturesIds: "multivendor",
		},
		{
			icon: "zig-tag",
			label: t("zigTag"),
			url: `${baseUrl}/zig-tags`,
		},
		{
			icon: "pre-charge",
			label: t("rechargeManagement"),
			url: `${baseUrl}/recharge-management`,
			permissions: ["manageRecharge"],
		},
	];

	const items = showMenuV2
		? useNavigationWithRestriction<NavBarRouteV2>(routesV2)
		: useNavigationWithRestriction(routesV1);

	if (showMenuV2) {
		return (
			<NavBarV2
				items={items.restrictedRoutes as NavBarRouteV2[]}
				isOpenSandwichMenu={isOpenSandwichMenu}
				onChangeIsOpenSandwichMenu={onChangeIsOpenSandwichMenu}
				selectedMenu={currentSelectedMenu}
			/>
		);
	}

	return (
		<NavBar
			type="event"
			items={items.restrictedRoutes as NavBarRoute[]}
			isOpenSandwichMenu={isOpenSandwichMenu}
			onChangeIsOpenSandwichMenu={onChangeIsOpenSandwichMenu}
		/>
	);
}

const EventNavBar = React.memo(observer(Nav));

export default EventNavBar;
