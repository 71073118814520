import {
	Couvert,
	FiscalPrinter,
	ImageUrl,
	NewPlace,
	Place,
	PlaceProduct,
	PlaceSellVisualizationFormat,
	PlaceStatus,
	UserDocumentType,
} from "#api/enterprise-generated";
import PlaceStore from "#stores/place";
import { computed, observable } from "mobx";
import { BarStore } from "#stores/bar";
import { EventStore } from "#stores/event";
import { setObservableData } from "#helpers/mobx/utils";

/**  @deprecated models should not ne used anymore */
export class PlaceModel implements Place {
	@observable
	public placeStore: PlaceStore;
	@observable
	public eventStore: EventStore;
	@observable
	public barStore: BarStore;
	@observable
	public id!: string;
	@observable
	public name!: string;
	@observable
	public image!: ImageUrl | null;
	@observable
	public postPaidLimit!: number | null;
	@observable
	public maleCouvert!: Couvert | null;
	@observable
	public femaleCouvert!: Couvert | null;
	@observable
	public tip!: number;
	@observable
	public zigTagProduct!: PlaceProduct | null;
	@observable
	public fiscalPrinters!: FiscalPrinter[];
	@observable
	public rechargeNotIntegratedLimit!: number | null;
	@observable
	public reportMails!: string[] | null;
	@observable
	public sellVisualizationFormat!: PlaceSellVisualizationFormat;
	@observable
	public localServerIp!: string | null;
	@observable
	public isApproved!: boolean;
	@observable
	currency!: string | null;
	@observable
	currencySymbol!: string | null;
	@observable
	public isBlocked!: boolean;
	@observable
	public status!: PlaceStatus;
	@observable
	public withdrawRequestConfirmationContactEmail!: string | null;
	@observable
	public documentType!: UserDocumentType | null;
	@observable
	public countryISO3!: string | null;
	@observable
	public peopleCapacity!: number | null;
	@observable
	public defaultReportSinceDate!: Date | null;
	@observable
	public defaultReportUntilDate!: Date | null;

	@computed
	get bars() {
		return this.barStore.bars.filter(bar => bar.placeId === this.id);
	}

	constructor(
		placeStore: PlaceStore,
		barStore: BarStore,
		eventStore: EventStore,
		place: Place,
	) {
		this.placeStore = placeStore;
		this.barStore = barStore;
		this.eventStore = eventStore;
		this.sync(place);
	}

	public sync = (place: Place) => {
		const { bars, ...placeWithOutBars } = place;
		this.barStore.resolve(...bars.map(b => ({ ...b, placeId: place.id! })));
		setObservableData(this, placeWithOutBars);
	};

	public edit = (place: Place, image?: Buffer) => {
		const newPlace: NewPlace = {
			id: place.id,
			name: place.name,
			postPaidLimit: place.postPaidLimit,
			maleCouvert: place.maleCouvert!,
			femaleCouvert: place.femaleCouvert!,
			bars: place.bars,
			tip: place.tip,
			sellVisualizationFormat: place.sellVisualizationFormat,
			zigTagProductId: place.zigTagProduct!.id,
			localServerIp: place.localServerIp,
			rechargeNotIntegratedLimit: place.rechargeNotIntegratedLimit,
			reportMails: place.reportMails,
		};

		this.placeStore.editPlace(newPlace, image);
	};

	public fetchAllEvents() {
		this.eventStore.fetchAllEvents(this.id!);
	}
}
