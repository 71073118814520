export const globalKeysPT = {
	clean: "Limpar",
	export: "Exportar",
	downloadExcel: "Download em Excel",
	import: "Importar",
	name: "Nome",
	cancel: "Cancelar",
	code: "Código",
	goBack: "Voltar",
	goFoward: "Avançar",
	next: "Próximo",
	confirm: "Confirmar",
	yes: "Sim",
	no: "Não",
	yesCaps: "SIM",
	noCaps: "NÃO",
	truthCaps: "VERDADE",
	falseCaps: "FALSO",
	all: "Todos",
	download: "Baixar",
	grams: "gramas",
	kilos: "Kilos",
	liter: "Litros",
	loading: "Carregando",
	by: "por",
	transfer: "Transferir",
	notTested: "Não testado",
	tested: "Testado",
	error: "Erro",
	delete: "Deletar",
	finished: "Concluído",
	edit: "Editar",
	add: "Adicionar",
	conclude: "Concluir",
	erase: "Apagar",
	remove: "Remover",
	search: "Buscar",
	sendMessageNow: "Enviar mensagem agora",
	message: "Mensagem",
	messages: "Mensagens",
	and: "e",
	more: "mais",
	gender: {
		male: "Homem",
		female: "Mulher",
		others: "Outros",
	},
	daysOfTheWeek: {
		monday: "Segunda",
		tuesday: "Terça",
		wednesday: "Quarta",
		thursday: "Quinta",
		friday: "Sexta",
		saturday: "Sábado",
		sunday: "Domingo",
		mon: "Seg",
		tue: "Ter",
		wed: "Qua",
		thu: "Qui",
		fri: "Sex",
		sat: "Sáb",
		sun: "Dom",
	},
	months: {
		january: "Janeiro",
		february: "Fevereiro",
		march: "Março",
		april: "Abril",
		may: "Maio",
		june: "Junho",
		july: "Julho",
		august: "Agosto",
		september: "Setembro",
		october: "Outubro",
		november: "Novembro",
		december: "Dezembro",
	},
	new: "Novo",
	order: "Ordernar",
	period: "Período",
	phone: "Telefone",
	preview: "Visualizar",
	seeAttachments: "Ver anexos",
	previous: "Anterior",
	print: "Imprimir",
	printReport: "Imprimir relatório",
	reload: "Recarregar",
	request: "Solicitar",
	requiredField: "Campo Obrigatório",
	save: "Salvar",
	seeLess: "Ver menos",
	seeMore: "Ver mais",
	select: "Selecione",
	selected: "Selecionado",
	selectedPlural: "Selecionados",
	send: "Enviar",
	shortMonths: {
		jan: "Jan",
		feb: "Fev",
		mar: "Mar",
		apr: "Abr",
		may: "Mai",
		jun: "Jun",
		jul: "Jul",
		aug: "Ago",
		sep: "Set",
		oct: "Out",
		nov: "Nov",
		dec: "Dez",
	},
	typeHere: "Digite aqui",
	continue: "Continuar",
	close: "Fechar",
	tryAgain: "Tentar novamente",
	attention: "Atenção",
	create: "Criar",
	days: "Dias",
	exclude: "Excluir",
	from: "De",
	to: "Para",
	value: "Valor",
	day: "Dia",
	true: "Verdadeiro",
	false: "Falso",
	issue: "Emitir",
	currencies: {
		real: "Real",
		dollar: "Dólar",
		mexPeso: "Peso mexicano",
		euro: "Euro",
	},
	comingSoon: "Em breve",
	pending: "Pendente",
	invalid: "Inválido",
	today: "Hoje",
	yesterday: "Ontem",
	tomorrow: "Amanhã",
	disable: "Desativar",
	disabled: "Desativado",
	daysOfTheWeekComplete: {
		monday: "Segunda-feira",
		tuesday: "Terça-feira",
		wednesday: "Quarta-feira",
		thursday: "Quinta-feira",
		friday: "Sexta-feira",
		saturday: "Sábado",
		sunday: "Domingo",
		holidays: "Feriados",
	},
	daysOfTheWeekSmall: {
		mon: "Seg",
		tue: "Ter",
		wed: "Qua",
		thu: "Qui",
		fri: "Sex",
		sat: "Sáb",
		sun: "Dom",
		hol: "Fer",
	},
	typeDate: {
		Day: "Dia",
		Week: "Semana",
		Month: "Mes",
		Year: "Ano",
	},
	typeDatePlural: {
		Days: "Dias",
		Weeks: "Semanas",
		Months: "Meses",
		Years: "Anos",
	},
	upload: "Upload",
	leave: "Sair",
	toSelect: "Selecionar",
	cpf: "CPF",
	unknown: "Anônimo",
	uid: "Zig Code",
	withoutPermission: "Você não possui permissões",
	exportExcel: "Exportar para Excel",
	withoutPermissionToViewPage: "Você não tem permissão para acessar essa página",
	update: "Atualizar",
	enable: "Ativar",
	enabled: "Ativado",
	dataSavedSuccess: "Dados salvos com sucesso",
	successfullExportMsg: "Exportação realizada com sucesso",
	backToMainPage: "Voltar para página principal",
	somethingWentWrong: "Ops... Algo não saiu como planejado.",
	savingData: "Salvando dados",
	subtotal: "Subtotal",
	total: "Total",
	type: "Tipo",
	invalidEmail: "Email inválido",
	emptyText: "Sem dados a serem exibidos.",
	of: "de",
	noneOfTheOptions: "Nenhuma das opções",
	transaction: "Transação",
	page: "página",
	recharge: "Recarga",
	applyFilter: "Aplicar filtro",
	date: "Data",
	since: "De",
	until: "Até",
};
