import { audits } from "./audits";
import { barReports } from "./bar-reports";
import { businessIntelligence } from "./business-intelligence/";
import { discountCategory } from "./discount-category";
import { emissionErrors } from "./emission-errors";
import { emited } from "./emited";
import { entrance } from "./entrance";
import { entranceBilling } from "./entrance-billing/";
import { events } from "./events/";
import { extract } from "./extract";
import { passwordCard } from "./password-card";
import { notEmitedProducts } from "./not-emited-products";
import { promotion } from "./promotion";
import { search } from "./search";
import { transactions } from "./transactions";
import { recharges } from "./recharges";
import { invoices } from "./invoices";
import { kardex } from "./kardex";
import { multivendor } from "./multivendor";
import { coala } from "./coala";
import { financialReport } from "./financial-report/";
import { fiscalReport } from "./fiscal-report";
import { guestList } from "./guest-list/";
import { pos } from "./pos";
import { precharge } from "./precharge";
import { products } from "./products";
import { productMenu } from "./product-menu";
import { tagGroups } from "./tag-groups";
import { promotions } from "./promotions";
import { settings } from "./settings";
import { storage } from "./storage/";
import { whatsUp } from "./whats-up";
import { importXml } from "./import-xml";
import { reserve } from "./reserve";
import { productDetail } from "./products/product-detail";
import { productArea } from "./products/product-area";
import { inventoryControl } from "./inventory-control";
import { prechargeReports } from "./precharge-reports";
import { contracts } from "./contracts";
import { rechargeManagement } from "./recharge-management";
import { transactionsReport } from "./transactions-report";
import { bonusCategories } from "./bonus-categories";

export const placeEN = {
	placeTitle: "Places",
	searchPlaceInputPlaceholder: "Search for a place",
	placeWithoutEvent: "Places without event for more than a month",
	placeWithOpenEvent: "Places with an event in progress",
	placeWithoutEventInProgress: "Places without an event in progress",
	timezone: "Timezone",
	currency: "Currency",
	subtitle: "Legend",
	alphabeticalOrder: "Alphabetical order",
	lastEventOrder: "Last created event order",
	subtitles: {
		onGoingEvent: "Ongoing event",
		recentEvent: "Recent event",
		longTimeWithoutEvent: "Long time without event",
		waitingForApproval: "Waiting for approval",
		noEvent: "No event",
	},
	placelinks: {
		post: "Post",
		pre: "Pre",
		noEvents: "Without events",
		none: "No event",
		openEvent: "Open event",
	},
	placeTabs: {
		products: " Products",
		combos: " Combos",
		supplies: " Inputs",
		bars: " Bars",
		beer: " Draft Beer",
		gng: " Grab and Go",
		cmv: " Eng. from Menu",
	},
	events,
	products,
	productMenu,
	businessIntelligence,
	entranceBilling,
	reserve,
	storage,
	financialReport,
	guestList,
	entrance,
	tagGroups,
	pos,
	promotions,
	whatsUp,
	audits,
	settings,
	precharge,
	barReports,
	extract,
	passwordCard,
	notEmitedProducts,
	promotion,
	search,
	transactions,
	invoices,
	kardex,
	multivendor,
	coala,
	fiscalReport,
	discountCategory,
	emissionErrors,
	emited,
	importXml,
	productDetail,
	productArea,
	inventoryControl,
	prechargeReports,
	contracts,
	rechargeManagement,
	recharges,
	transactionsReport,
	bonusCategories,
};
