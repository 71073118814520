export const globalKeysEN = {
	clean: "Clean",
	export: "Export",
	import: "Import",
	name: "Name",
	cancel: "Cancel",
	code: "Code",
	goBack: "Go back",
	goFoward: "Forward",
	next: "Next",
	confirm: "Confirm",
	yes: "Yes",
	no: "No",
	yesCaps: "YES",
	truthCaps: "TRUE",
	falseCaps: "FALSE",
	all: "All",
	download: "Download",
	transfer: "Transfer",
	notTested: "Not tested",
	tested: "Tested",
	error: "Error",
	delete: "Delete",
	finished: "Finished",
	edit: "Edit",
	add: "Add",
	conclude: "Finish",
	erase: "Erase",
	remove: "Remove",
	search: "Search",
	sendMessageNow: "Send message now",
	message: "Message",
	messages: "Messages",
	and: "and",
	more: "more",
	gender: {
		male: "Male",
		female: "Female",
		others: "Other",
	},
	daysOfTheWeek: {
		monday: "Monday",
		tuesday: "Tuesday",
		wednesday: "Wednesday",
		thursday: "Thursday",
		friday: "Friday",
		saturday: "Saturday",
		sunday: "Sunday",
		mon: "Mon",
		tue: "Tue",
		wed: "Wed",
		thu: "Thu",
		fri: "Fri",
		sat: "Sat",
		sun: "Sun",
	},
	months: {
		january: "January",
		february: "February",
		march: "March",
		april: "April",
		may: "May",
		june: "June",
		july: "July",
		august: "August",
		september: "September",
		october: "October",
		november: "November",
		december: "December",
	},
	new: "New",
	noCaps: "NO",
	order: "Order",
	period: "Period",
	phone: "Phone",
	preview: "Preview",
	seeAttachments: "See Attachments",
	previous: "Previous",
	print: "Print",
	printReport: "Print Report",
	reload: "Reload",
	request: "Request",
	requiredField: "Required field",
	save: "Save",
	seeLess: "See less",
	seeMore: "See more",
	select: "Select",
	selected: "Selected",
	selectedPlural: "Selected",
	send: "Send",
	shortMonths: {
		jan: "Jan",
		feb: "Feb",
		mar: "Mar",
		apr: "Apr",
		may: "May",
		jun: "Jun",
		jul: "Jul",
		aug: "Aug",
		sep: "Sep",
		oct: "Oct",
		nov: "Nov",
		dec: "Dec",
	},
	typeHere: "Type here",
	continue: "Continue",
	close: "Close",
	attention: "Attention",
	grams: "grams",
	kilos: "Kilos",
	liter: "Liter",
	by: "by",
	create: "create",
	tryAgain: "Try again",
	days: "Days",
	exclude: "Delete",
	from: "From",
	to: "To",
	value: "Value",
	day: "Day",
	true: "True",
	false: "False",
	issue: "Issue",
	currencies: {
		real: "Real",
		dollar: "Dollar",
		mexPeso: "Mexican peso",
		euro: "Euro",
	},
	comingSoon: "Coming soon",
	pending: "Pending",
	invalid: "Invalid",
	today: "Today",
	yesterday: "Yesterday",
	tomorrow: "Tomorrow",
	disable: "Disable",
	disabled: "Disabled",
	daysOfTheWeekComplete: {
		monday: "Monday",
		tuesday: "Tuesday",
		wednesday: "Wednesday",
		thursday: "Thursday",
		friday: "Friday",
		saturday: "Saturday",
		sunday: "Sunday",
		holidays: "Holidays",
	},
	daysOfTheWeekSmall: {
		mon: "Mon",
		tue: "Tue",
		wed: "Wed",
		thu: "Thu",
		fri: "Fri",
		sat: "Sat",
		sun: "Sun",
		hol: "hol",
	},
	typeDate: {
		Day: "Day",
		Week: "Week",
		Month: "Month",
		Year: "Year",
	},
	typeDatePlural: {
		Days: "Days",
		Weeks: "Weeks",
		Months: "Months",
		Years: "Years",
	},
	upload: "Upload",
	leave: "Exit",
	toSelect: "Select",
	cpf: "CPF",
	unknown: "Anonymous",
	uid: "Zig Code",
	withoutPermission: "You don't have the permissions",
	exportExcel: "Export to Excel",
	withoutPermissionToViewPage: "Not allowed to view the page",
	update: "Update",
	enable: "Activate",
	enabled: "Activated",
	dataSavedSuccess: "Data saved successfully",
	backToMainPage: "Back to main page",
	somethingWentWrong: "Ops... Something went wrong",
	savingData: "Saving data",
	subtotal: "Subtotal",
	total: "Total",
	type: "Type",
	invalidEmail: "Invalid email",
	emptyText: "No data to display.",
	of: "of",
	noneOfTheOptions: "None of the options",
	transaction: "Transaction",
	page: "page",
	recharge: "Recharge",
	date: "Date",
	since: "Since",
	until: "Until",
};
