import { RootStore } from ".";
import { fetchModel } from "#helpers/fetch-model";
import enterprise from "#resources/api/enterprise-client";
import { showErrorNotification } from "#resources/helpers/notifications";
import {
	AnswerDetails,
	CustomerResult,
	NpsPlaceInfo,
	NpsQuestionDetails,
	PageArgs,
	QuestionsScore,
	QuestionType,
} from "#resources/api/enterprise-generated";

export class NpsStore {
	public rootStore: RootStore;

	constructor(rootSore: RootStore) {
		this.rootStore = rootSore;
	}

	public getNpsFromPlaces = new fetchModel<
		{
			placeIds: string[];
			since: Date;
			until: Date;
		},
		QuestionsScore
	>({
		fnPromise: args => enterprise.getNpsFromPlaces(args),
		onError: err => showErrorNotification(err.message),
	});

	public editNpsQuestions = new fetchModel<
		{
			placeId: string;
			questions: QuestionType[];
		},
		NpsPlaceInfo
	>({
		fnPromise: args => enterprise.editNpsQuestions(args),
		onError: err => showErrorNotification(err.message),
	});

	public getNpsCustomerAnswers = new fetchModel<
		{ placeIds: string[]; since: Date; until: Date; pageConfig: PageArgs },
		CustomerResult
	>({
		fnPromise: args => enterprise.getNpsCustomerAnswers(args),
		onError: err => showErrorNotification(err.message),
	});

	public getNpsConfig = new fetchModel<{ placeIds: string[] }, NpsPlaceInfo[]>({
		fnPromise: args => enterprise.getNpsConfig(args),
		onError: err => {
			throw Error(err.message);
		},
	});

	public editSendSmsOnNpsForm = new fetchModel<
		{ placeId: string; shouldSendSmsOnCheckout: boolean },
		unknown
	>({
		fnPromise: args => enterprise.editSendSmsOnNpsForm(args),
		onError: err => showErrorNotification(err.message),
	});

	public getCustomerAnswerDetails = new fetchModel<
		{ userId: string; eventId: string },
		AnswerDetails
	>({
		fnPromise: args => enterprise.getCustomerAnswerDetails(args),
		onError: err => {
			throw Error(err.message);
		},
	});

	public getNpsQuestionDetails = new fetchModel<
		{ questionId: QuestionType; placeIds: string[]; since: Date; until: Date },
		NpsQuestionDetails
	>({
		fnPromise: args => enterprise.getNpsQuestionDetails(args),
		onError: err => {
			throw Error(err.message);
		},
	});
}
