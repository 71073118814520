export const transactionsReport = {
	title: "Relatório de Transações",
	filter: {
		title: "Filtrar",
		emptyFilter: "Clique para filtrar",
		modal: {
			inputPlaceholder: "Digite aqui",
			selectPlaceholder: "Selecione aqui",
			descriptionEnd: "para filtrar as transações",
		},
		modalOptions: {
			terminal: {
				label: "Terminal",
				title: "Número do terminal",
				description: "Insira o número do terminal",
			},
			clientDocument: {
				label: "Documento do cliente",
				title: "CPF ou número de documento",
				description: "Insira o CPF ou número de documento do cliente",
			},
			employee: {
				label: "Funcionário",
				title: "Nome do funcionário",
				description: "Insira o nome do funcionário",
			},
			zigTag: {
				label: "ZigTag",
				description: "Insira o número do ZigTag",
			},
			paymentMethod: {
				label: "Forma de pagamento",
				description: "Selecione uma forma de pagamento",
			},
			transactionType: {
				label: "Tipo de transação",
				description: "Selecione um tipo de transação",
			},
			bar: {
				label: "Filtro por bar",
				title: "Bar",
				description: "Selecione um bar",
			},
		},
	},
	print: "Imprimir relatório",
};
