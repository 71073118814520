export const loyalty = {
	title: "Customer loyalty",
	commingSoon: {
		subtitle:
			"Improve your results and your customers' experience with loyalty programs.",
		description:
			"You can choose from various ways for your customers to earn points and the rewards they will receive. Increase customer retention by incentivizing them to visit locations or purchase a product in exchange for points, among other options available in the loyalty module.",
		cards: {
			retainCustomers: {
				title: "Retain customers",
				description:
					"Choose participating locations, rewards, points and engage your customers.",
			},
			trackResults: {
				title: "Track the results",
				description: "You can monitor the performance of your loyalty programs.",
			},
			paper: {
				title: "Say goodbye to paper.",
				description:
					"Your customers can monitor and redeem their earned points through the Zig.app.",
			},
		},
		tabs: {
			programs: {
				createSteps: {
					points: {
						pointRules: {
							npsAnswer: {
								mainTitle: "NPS Answer",
								description:
									"Define the amount of points that customers will earn when they complete the satisfaction survey",
								detailTitle: "Amount of points per satisfaction survey response",
							},
						},
						npsAnswerPoints: {
							inputLabel: "Points",
							inputPlaceholder: "Enter the points per NPS response",
							continueButtonText: "Add points",
						},
					},
				},
			},
		},
	},
};
