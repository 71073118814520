import enterprise from "#resources/api/enterprise-client";
import { EventList, SimplifiedPlace } from "#resources/api/enterprise-generated";
import { EnterpriseApiSignature } from "#resources/helpers/api";
import { SdkgenError } from "@sdkgen/browser-runtime";
import { useQueries, UseQueryOptions } from "@tanstack/react-query";
import { ORG_KEYS } from "./keys";

const ONE_SECOND_IN_MILLISECONDS = 1000;

type GetEventsSignature = EnterpriseApiSignature<"getEvents">;
type UseGetEventsOptions = Omit<
	UseQueryOptions<GetEventsSignature["result"], SdkgenError>,
	"queryKey"
>;

export const useGetActiveEventsBySimplifiedPlaces = <TData = EventList>(
	params: { simplifiedPlaces: SimplifiedPlace[] },
	options?: UseGetEventsOptions,
	select?: (data: EventList, place: SimplifiedPlace) => TData,
) => {
	const STALE_TIME = ONE_SECOND_IN_MILLISECONDS * 120; // 2min

	return useQueries({
		queries: params.simplifiedPlaces.map(place => {
			const placeId = place.id;
			const lastEventBeginDate = place.lastEventBeginDate!;
			const month = lastEventBeginDate.getMonth();
			const year = lastEventBeginDate.getFullYear();

			return {
				staleTime: STALE_TIME,
				...options,
				queryKey: ORG_KEYS.getActiveEventsBySimplifiedPlace(placeId, `${month}/${year}`),
				queryFn: () => enterprise.getEvents({ placeId, month, year }),
				select: (data: EventList) => {
					return select?.(data, place);
				},
			};
		}),
	});
};
