import { accountsPayableReceivable } from "./accounts-payable-receivable";
import { bills } from "./bills";
import { finExManagerialReport } from "./finex-managerial-report";
import { planOfAccounts } from "./plan-of-accounts";
import { customerAndSupplier } from "./customer-and-supplier";
import { stockTransferHistory } from "./stock-transfer-history";

export const backofficeEN = {
	accountsPayableReceivable,
	stockTransferHistory,
	bills,
	cashFlow: {
		pageTitle: "Cash Flow",
		pageParagraph:
			"This tool makes it possible to determine the current available balance and project the future, so that there is always working capital available both to fund the company's operations and to invest in improvements.",
		tooltipMessage:
			"The category lines of level {{level}} display the sum of all the levels grouped within it.",
		fileName: "cash-flow",
		topActions: {
			lblAccountName: "Search by Account",
			plhAccountName: "Enter the account name",
			lblDate: "Filter by Expiration",
			btnPdf: "Export to PDF",
			btnExcel: "Export to Excel",
		},
		totalizers: {
			income: "Total revenues",
			costs: "Total costs",
			expenses: "Total expenses",
			balance: "Total of the Period",
		},
		rowTotal: "Period Balance",
		rowHead: "Initial Balance",
		rowTail: "Balance for the Day",
		viewPerDay: "View per day",
		viewPerMonth: "View per month",
		columns: {
			income: "Revenues",
			costs: "Costs",
			expenses: "Expenses",
			level: "Level",
			name: "Name",
		},
	},
	costCenter: {
		costCenter: "Cost centers",
		addCostCenter: "Add cost center",
		editCostCenter: "Edit cost center",
		namePlaceholder: "Cost center name",
		name: "Name",
		action: "Actions",
	},
	planOfAccounts,
	accounts: {
		accounts: "Checking Accounts",
		addAccount: "Add new account",
		form: {
			addAccount: "Add checking account",
			name: "Account name",
			namePlaceholder: "Enter an account name",
			bank: "Bank",
			bankPlaceholder: "Search for bank",
			purpose: "Account purpose",
			purposePlaceholder: "Search for account type",
			purposeList: {
				advance: "Advance",
				cardManager: "Card Manager",
				inHouseFlow: "In-House Flow",
				tip: "Tip",
				creditCard: "Credit Card",
				virtualWallet: "Virtual Wallet",
				applicationAccount: "Application Account",
				checkingAccount: "Checking Account",
				paymentAccount: "Payment Account",
				receivingAccount: "Receiving Account",
				loanAccount: "Loan Account",
				guaranteedAccount: "Guaranteed Account",
				savingsAccount: "Savings Account",
			},
			account: "Checking account with digit",
			accountPlaceholder: "Enter account",
			agency: "Agency",
			agencyPlaceholder: "enter code",
			cpfOrCnpj: "CPF/CNPJ",
			cpfPlaceholder: "000.000.000-00",
			ownerName: "Owner Name",
			ownerNamePlaceholder: "Account's owner name",
			unlink: "Unlink Bank Account",
			linkBankAccount: "Link bank account",
			defaultAccount: "Set as main account",
			defaultAccountTooltip:
				"The main account is used to automatically fill in forms that require this information.",
			actions: "Actions",
			default: "Default",
			initialBalance: "Initial balance",
			initialBalanceDate: "Initial balance date",
			balancePlaceholder: "0,00",
			balanceDatePlaceholder: "dd/mm/yyyy",
			saveChanges: "Save changes",
			errors: {
				required: "Required field",
				name: "Enter a valid name",
				account: "Enter a valid account number",
				agency: "Enter a valid agency number",
				document: "Enter a valid CPF/CNPJ",
			},
		},
		confirmModal: {
			editInfo: "Edit informations",
			cancelMessage: "You have not saved your changes. Do you want to continue anyway?",
			cancelButton: "Exit without saving",
			confirmButton: "Save changes",
			deleteAccount: "Delete account",
			deleteMessage:
				"Deleting an account is a permanent action. Do you want to proceed with the deletion?",
			deleteTransferError:
				"The deletion of this account cannot be performed as it is linked to recorded payments or receipts.",
			deleteMainError:
				"The deletion of this account cannot be performed as it is registered as main account",
			updateMainAccount: "Change main account",
			updateMainAccountMessage:
				"There is already an account registered as the main account at this location. If you proceed, this account will be replaced. Do you still want to continue?",
			confirmUpdate: "Change account",
		},
		emptyStateTitle: "You don't have registered accounts yet",
		emptyStateSubtitle: "Click add new account to get started",
		notification: {
			deleteAccount: "Account successfully deleted",
			createAccount: "Account successfully created",
			updateAccount: "Account successfully edited",
		},
	},
	reports: {
		noComponent: "Wait, it will be available soon",
		reportNames: {
			expense: {
				perDescripion: "Expense by Description",
				perDay: "Expense per Day",
				perType: "Expense by Type",
				perCategory: "Expense by Category",
				perCostCenter: "Expense by Cost Center",
				perHistory: "Historical Expense",
				perAnything: "Expense Paid to...",
			},
			receipts: {
				perDescripion: "Receipts by Description",
				perDay: "Receipts per Day",
				perType: "Receipts by Type",
				perCategory: "Receipts by Category",
				perCostCenter: "Receipts by Cost Center",
				perHistory: "Historical Receipts",
				perAnything: "Receipts Received from...",
			},
			cashFlow: {
				extract: "Extract",
				expensses: "Expenses/Receipts",
				history: "Historic",
				demonstrative: "Demonstrative",
			},
		},
		graphs: {
			totalExpenses: "Total expenses",
			totalReceipts: "Total receipts",
			finalBalance: "Final balance",
			periodBalance: "Period balance",
			previousBalance: "Previous balance",
			date: "Date",
			description: "Description",
			category: "Category",
			receipts: "Receipt",
			expenses: "Expenses",
			dateCaps: "DATE",
			receiptCaps: "RECEIPT",
			expenseCaps: "EXPENSE",
			valueCaps: "VALUE",
			totalCaps: "TOTAL",
			type: "Type",
			costCenterCaps: "COST CENTER",
			tax: "Tax",
			receipt: "Receipt",
			expense: "Expense",
			result: "Income before tax",
			profit: "Profit in exercise",
			descriptionCaps: "DESCRIPTION",
			value: "Value",
			balance: "Balance",
			period: "Period",
			until: "until",
			totalReceived: "Total Received",
			totalPaid: "Total paid",
			finalBalanceExtract: "Ending balance",
			periodBalanceExtract: "Balance in the period",
			previousBalanceExtract: "Previous balance",
			supplierCaps: "SUPPLIER",
			typeCaps: "TYPE",
		},
	},
	billType: {
		categories: "Categories",
		addCategory: "Add account category",
		addType: "Add account type",
		createCategory: "Create account category",
		editCategory: "Edit account category",
		categoryName: "Account category name",
		createTypeIn: "Create account type in {{categoryName}}",
		editTypeIn: "Edit account type in {{categoryName}}",
		createType: "Create account type",
		editType: "Edit account type",
		typeName: "Account type name",
		name: "Name",
		actions: "Actions",
	},
	finExManagerialReport,
	customerAndSupplier,
	viewLevel: "View level {{level}}",
};
