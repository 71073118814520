import { observable } from "mobx";
import { autobind } from "core-decorators";
import { showErrorNotification, showSuccessNotification } from "#helpers/notifications";
import {
	AddMultipleEmployees,
	BarOrEmployeeFinance,
	EditMultipleEmployees,
	Employee,
	EmployeeV2,
	GetEmployeeTypes,
	MultipleEmployeeResult,
	MultipleEmployees,
	NewEmployee,
	Permission,
	Role,
} from "#resources/api/enterprise-generated";
import enterprise from "#resources/api/enterprise-client";
import { fetchModel } from "#resources/helpers/fetch-model";
import i18n from "#i18n/index";

const t = i18n.t;

@autobind
export class EmployeeStore {
	public clean() {
		this.employees = [];
		this.roles = [];
		this.permissions = [];
	}

	// EMPLOYEES

	@observable
	public employees: EmployeeV2[] = [];

	private removeEmployeeFromLocaly = (employeeId: string) => {
		this.employees = this.employees.filter(e => e.id !== employeeId);
	};

	public getEmployees = new fetchModel<{}, EmployeeV2[]>({
		fnPromise: args => enterprise.getEmployeesV2(args),
		onSuccess: rtn => (this.employees = rtn),
		onError: err => showErrorNotification(err.message),
	});

	public getEmployee = new fetchModel<
		{
			employeeId: string;
		},
		Employee
	>({
		fnPromise: args => enterprise.getEmployee(args),
		onError: err => showErrorNotification(err.message),
	});

	public addZigTagToWaiter = new fetchModel<
		{
			zigCode: string;
			employeeId: string;
		},
		void
	>({
		fnPromise: args => enterprise.addZigTagToWaiter(args),
		onSuccess: _rtn => this.getEmployees.fetch({}),
		onError: err => showErrorNotification(err.message),
	});

	public addEmployee = new fetchModel<
		{
			employee: NewEmployee;
			password: string;
			image?: Buffer | null | undefined;
		},
		string
	>({
		fnPromise: args => enterprise.addEmployee(args),
		onSuccess: () => {
			this.getEmployees.fetch({});
			showSuccessNotification(t("store:employeeStore.addEmployee"));
		},
		onError: err => showErrorNotification(err.message),
	});

	public editEmployee = new fetchModel<
		{
			employeeId: string;
			employee: NewEmployee;
			password?: string;
			avatar?: Buffer;
			zigtag?: string;
		},
		void
	>({
		fnPromise: args => {
			return new Promise(async (resolve, reject) => {
				await enterprise
					.editEmployee({
						id: args.employeeId,
						employee: args.employee,
					})
					.catch(reject);

				if (args.password) {
					await enterprise
						.editEmployeePassword({
							id: args.employeeId,
							password: args.password,
						})
						.catch(reject);
				}

				if (args.avatar) {
					await enterprise
						.editEmployeeImage({
							id: args.employeeId,
							data: args.avatar,
						})
						.catch(reject);
				}

				if (args.zigtag) {
					await enterprise
						.addZigTagToWaiter({
							employeeId: args.employeeId,
							zigCode: args.zigtag,
						})
						.catch(reject);
				}

				resolve();
			});
		},
		onSuccess: async _rtn => {
			this.getEmployees.fetch({});
			showSuccessNotification(t("store:employeeStore.editEmployee"));
		},
		onError: err => showErrorNotification(err.message),
	});

	public removeEmployee = new fetchModel<
		{
			id: string;
		},
		void
	>({
		fnPromise: args => enterprise.removeEmployee(args),
		onSuccess: (_rtn, args) => {
			this.removeEmployeeFromLocaly(args.id);
			showSuccessNotification(t("store:employeeStore.removeEmployee"));
		},
		onError: err => showErrorNotification(err.message),
	});

	public editEmployeeImage = new fetchModel<
		{
			id: string;
			data: Buffer;
		},
		void
	>({
		fnPromise: args => enterprise.editEmployeeImage(args),
		onError: err => showErrorNotification(err.message),
	});

	public editEmployeePassword = new fetchModel<
		{
			id: string;
			password: string;
		},
		void
	>({
		fnPromise: args => enterprise.editEmployeePassword(args),
		onError: err => showErrorNotification(err.message),
	});

	public addMultipleEmployees = new fetchModel<
		{
			employee: MultipleEmployees;
			password: string;
			image?: Buffer | null | undefined;
		},
		AddMultipleEmployees
	>({
		fnPromise: args => enterprise.addMultipleEmployees(args),
		onError: err => showErrorNotification(err.message),
	});

	public editMultipleEmployees = new fetchModel<
		{
			employeeIds: string[];
			edition: EditMultipleEmployees;
		},
		void
	>({
		fnPromise: args => enterprise.editMultipleEmployees(args),
		onSuccess: () =>
			showSuccessNotification(t("store:employeeStore.editMultipleEmployees")),
		onError: err => showErrorNotification(err.message),
	});

	public removeMultipleEmployees = new fetchModel<
		{
			employeeIds: string[];
		},
		MultipleEmployeeResult
	>({
		fnPromise: args => enterprise.removeMultipleEmployees(args),
		onSuccess: res => {
			if (res.success.length) {
				showSuccessNotification(
					`${t("store:employeeStore.removeMultipleEmployees", {
						successCount: res.success.length,
					})}`,
				);
			}

			if (res.error.length) {
				showErrorNotification(
					`${t("store:employeeStore.removeMultipleEmployeesError", {
						errorCount: res.error.length,
					})}`,
				);
			}
		},
		onError: err => showErrorNotification(err.message),
	});

	// EMPLOYEE ROLES

	@observable
	public roles: Role[] = [];

	public getAllRoles = new fetchModel<{}, Role[]>({
		fnPromise: args => enterprise.getAllRoles(args),
		onSuccess: roles => (this.roles = roles),
		onError: err => showErrorNotification(err.message),
	});

	public createRole = new fetchModel<
		{
			name: string;
			permissions: string[];
		},
		Role
	>({
		fnPromise: args => enterprise.createRole(args),
		onSuccess: () => showSuccessNotification(t("store:employeeStore.createRole")),
		onError: err => showErrorNotification(err.message),
	});

	public deleteRole = new fetchModel<
		{
			slug: string;
		},
		void
	>({
		fnPromise: args => enterprise.deleteRole(args),
		onSuccess: () => showSuccessNotification(t("store:employeeStore.deleteRole")),
		onError: err => showErrorNotification(err.message),
	});

	public updateRole = new fetchModel<
		{
			slug: string;
			name: string;
			permissions: string[];
		},
		Role
	>({
		fnPromise: args => enterprise.updateRole(args),
		onSuccess: () => showSuccessNotification(t("store:employeeStore.updateRole")),
		onError: err => showErrorNotification(err.message),
	});

	// EMPLOYEE PERMISSIONS

	@observable
	public permissions: Permission[] = [];

	public getAllPermissions = new fetchModel<{}, Permission[]>({
		fnPromise: async args => {
			const allPermissions = await enterprise.getAllPermissions(args);
			const permissionsToHide = [
				"authorizeHawkerBreak",
				"manageHawkerCycle",
				"refundViaPix",
			];

			return allPermissions.filter(p => !permissionsToHide.includes(p.slug));
		},
		onSuccess: permissions => (this.permissions = permissions),
		onError: err => showErrorNotification(err.message),
	});

	// EMPLOYEE REPORTS

	public getEstimatedFinanceDetailsByEmployeeAtEvent = new fetchModel<
		{
			eventId: string;
		},
		BarOrEmployeeFinance[]
	>({
		fnPromise: args => enterprise.getEstimatedFinanceDetailsByEmployeeAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getEmployeeTypes = new fetchModel<{}, GetEmployeeTypes[]>({
		fnPromise: args => enterprise.getEmployeeTypes(args),
		onError: err => showErrorNotification(err.message),
	});
}
