import { globalKeysPT } from "./global-keys";
import { placePT } from "./place";
import { orgPT } from "./org";
import { componentsPT } from "./components";
import { eventPT } from "./event";
import { navPT } from "./nav";
import { storePT } from "./store";
import { noAuthPT } from "./no-auth";
import { backofficePT } from "./backoffice";
import { sharedPT } from "./shared";

export const pt = {
	globalKeys: globalKeysPT,
	place: placePT,
	org: orgPT,
	components: componentsPT,
	event: eventPT,
	nav: navPT,
	store: storePT,
	noAuth: noAuthPT,
	backoffice: backofficePT,
	shared: sharedPT,
};
