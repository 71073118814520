export const transactionsReport = {
	title: "Transaction Report",
	filter: {
		title: "Filter",
		emptyFilter: "Click to filter",
		modal: {
			inputPlaceholder: "Type here",
			selectPlaceholder: "Select here",
			descriptionEnd: "to filter transactions",
		},
		modalOptions: {
			terminal: {
				label: "Terminal",
				title: "Terminal number",
				description: "Enter terminal number",
			},
			clientDocument: {
				label: "Customer document",
				title: "CPF or document number",
				description: "Enter the customer's CPF or document number",
			},
			employee: {
				label: "Employee",
				title: "Employee name",
				description: "Enter employee name",
			},
			zigTag: {
				label: "ZigTag",
				description: "Enter ZigTag number",
			},
			paymentMethod: {
				label: "Payment method",
				description: "Select a payment method",
			},
			transactionType: {
				label: "Transaction type",
				description: "Select a transaction type",
			},
			bar: {
				label: "Filter by bar",
				title: "Bar",
				description: "Select a bar",
			},
		},
	},
	print: "Print report",
};
