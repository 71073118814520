import { newEvent } from "./new-event";

export const events = {
	title: "Eventos em {{place}}",
	newEvent,
	lastBillingMonth: "Último mês de faturamento",
	eventsNumber: "eventos",
	totalClients: "total de clientes",
	averageTicket: "ticket médio",
	sales: "vendas",
	receipts: "recebimentos",
	card: {
		pdv: "PDV's em aberto",
		syncAfterClosed: "Subiu após o evento",
		lastSyncAfterClosedAt: "Última sincronização em:",
		averageTicket: "Ticket Médio",
		income: "Faturamento",
		clients: "Clientes",
		status: {
			created: "Fechado",
			finished: "Encerrado",
			running: "Aberto",
			canceled: "Cancelado",
		},
	},
	timeline: {
		label: "outros eventos futuros, modifique o período para ver mais",
	},
	tooltip: {
		calculation: "Memorial de cálculo: Valor total pago - Descontos concedidos",
		incomeSummaryCalc: "Valores recebidos em eventos realizados neste mês",
		minimumConsumption: "complemento de consumação mínima",
		productSold: "produtos vendidos",
		sales: "Vendas",
		service: "serviço",
		ticket: "entrada",
	},
};
