export const entrance = {
	title: "Appetizer",
	addEntrance: "Add entry",
	deleteEntrance: "Do you want to delete the {{entranceName}} entry?",
	table: {
		entrance: "Prohibited",
		category: "Category",
		value: "Value",
		consumptionObligation: "Early Consummation",
		minimumConsumptionValue: "Minimum consumption",
		active: "Active",
		variable: "Variable",
		bonus: "Bonus",
	},
	entranceForm: {
		addEntrance: "Add Entry",
		bonus: "Bonus",
		editEntrance: "Edit entry",
		details: "Entry information",
		name: "Name",
		namePlaceHolder: "Enter the name of the entry",
		category: "Category",
		categoryPlaceholder: "Select the category of the entry",
		price: "Price",
		variablePrice: "Variable price",
		consumptionObligation: "Minimum consumption",
		minimumValueCharged: "Minimum amount charged",
		minimumValueChargedTooltip:
			"The Minimum amount charged is the lowest amount that the customer will pay on the account and is the sum of the minimum consumption with the entry price",
		minimumConsumptionValue: "Early consumption",
		image: "Photograph",
		fiscalData: "Tax data",
		code: "SKU",
		codePlaceholder: "Enter the SKU code for the entry",
		ncm: "NCM",
		ncmPlaceholder: "Enter the NCM for this entry",
		cest: "CEST",
		cestPlaceholder: "Enter the CEST for the entry",
		fiscalProfile: "Tax profile",
		fiscalProfilePlaceholder: "Select the fiscal profile for the entry",
		fiscalGroup: "Tax group",
		fiscalGroupPlaceholder: "Select the fiscal group for the entry",
		newCategory: "You will create a new category",
	},
	errors: {
		mandatoryField: "This field is mandatory",
		validValue: "Please enter a valid value",
		consumption: "It is not possible to register more than one type of consummation",
	},
	edit: "To edit",
	add: "Add",
	cancel: "Cancel",
};
