export const precharge = {
	title: "Precharge operations",
	searchPlaceholder: "Search by precharge operation name",
	createNewPrecharge: "Create precharge operation",
	noSearchResults: "The search found no results",
	empty: "There are no precharge operations registered",
	card: {
		noTermsOfService: "No terms of service",
		noEnd: "No end",
	},
	addNewPrecharge: {
		prechargeOperation: "Precharge operations",
		siteConfig: "Site settings",
		eventName: "Event name",
		termsOfService: "Terms of Service",
		ticketUrl: "Ticket url",
		slug: "Slug",
		aliases: "Aliases",
		siteAppearance: "Site appearance",
		cover: "Cover",
		logo: "Logo",
		mainImage: "Main image",
		primaryColor: "Primary color",
		darkMode: "Dark Mode",
		operationConfig: "Operation settings",
		beginAt: "Starts at",
		endsAt: "Ends in",
		childFriendly: "Suitable for children",
		preSetedValues: "Pre-selectable values",
		bonus: "Bonus",
		minimumValue: "Minimum value",
		rechargeConfig: "Recharge settings",
		expirationDate: "Expiration date of recharges",
		validRechargDate: "Refills are valid from",
		rechargeDuration: "Recharge duration",
	},
	sideTab: {
		edit: "Edit precharge operation",
		withoutTerms: "No terms of service",
		preChargeOperation: "Precharge operation",
		slug: "SLUG",
		beginAt: "Starts at",
		endsAt: "Ends in",
		expiresAt: "Expiration date of recharges",
		undefined: "Undefined",
		validRecharges: "Recharges are valid from",
		darkMode: "Dark Mode",
		childFriendly: "Suitable for children",
		primaryColor: "Primary color",
		durability: "Recharge duration",
		remove: {
			title: "Are you sure you want to delete this precharge operation?",
			button: "Delete Precharge Operation",
		},
	},
	prechargeStore: {
		createPrechargeOperationSuccess: "Precharge operation created successfully",
		updatePrechargeOperationSuccess: "Precharge operation updated successfully",
	},
	homePage: {
		deleteConfirmation: "Click here to confirm the deletion of the operation",
		title: "Early Recharge",
		description:
			"Create a website for customers to recharge before the event starts, avoiding queues and consequently reducing the volume of operations at the event.",
		createButton: "Create recharge site",
		searchInputPlaceholder: "Search a site",
		willExpire: "Will expire in",
		hasExpired: "Expired on",
		copyLink: "Copy link to website",
		active: "Active",
		inactive: "Inactive",
		copied: "Link copied.",
	},
	formPage: {
		title: "Create recharge site",
		description:
			"Fill in the data and share the site with your customers. Allow users to recharge before the event starts, avoiding queues and consequently reducing the volume of operations at the event.",
		nextStep: "Next step",
		discard: "Discard creation",
		logoDescription: "The image must be in .jpeg or .png, with 880px by 880px",
		eventData: {
			logo: "Event logo",
			eventName: "Event name",
			eventNamePlaceholder: "Enter event name",
			email: "Contact email",
		},
		customization: {
			slug: "Website URL",
			slugPlaceholder: "Type event name",
			color: "Color of the details",
			rechargesPeriod: "Allow recharges within period of",
			endsAt: "Take the site down on",
			buffer: "Page featured photo",
			mainPageInfoTitle: "Homepage title",
			mainPageInfoTitleDescription:
				'Suggestion: Early recharge? "{{name}}" has! Plan ahead and avoid queues',
			mainPageInfoTitlePlaceholder: "Type here the title you want to place on the page",
			mainPageInfoSubtitle: "Supplementary text to the title",
			colorPlaceholder: "Select color",
		},
		rechargeSettings: {
			rechargesLimit: "Recharge limits",
			rechargesLimitDescription:
				"Establish the minimum amount per recharge and the maximum amount per user (sum of all recharges anticipated during the operation period).",
			minimumValue: "Minimum recharge value",
			maximumValuePerUser: "Maximum recharge value per user (operation period)",
			suggestion: "Recharge value suggestion",
			suggestionDescription:
				"On the recharge site, the user can select one of the values already suggested or enter the value he wants, respecting the minimum and maximum limits established above. Suggest 4 values to be displayed on the site.",
			preSetedValue: "Selectable values",
			bonus: "Bonus assignment",
			bonusDescription:
				"Did you know that you can gift your customers with bonuses? For each recharge value suggested above, you can link a bonus, if you wish.",
			paymentMethods: "Payment methods",
			paymentMethodsDescription:
				"Establish whether the recharge site will accept Pix only, credit card only, or both as a payment method.",
			bonusTable: {
				rechargeValue: "Recharge value",
				bonus: "Bonus",
			},
			addValue: "Add suggested value to website",
			suggestedValue: "Suggested values added",
			removeValue: "click the X to remove",
			noBonus: "No bonus",
		},
		tips: {
			yesOpt: "Yes. Add tips section on website.",
			noOpt: "No. Do not add tips section on site.",
			mainQuestion:
				"Would you like to insert a section with tips for customers to have the best possible experience at your event?",
			importantInfoQuestion:
				"Define important information about your event below, assigning them a title and text that complements it.",
			tipTitle: "Tip {{index}} - Title",
			tipTitlePlaceholder: "Type the tip title {{index}}",
			tipDescription: "Tip {{index}} - Complementary informative text to the title",
			tipDescriptionPlaceholder: "Enter the complementary text of the tip {{index}}",
			addMoreTip: "Add more tips",
			deleteTip: "Delete tip",
		},
		validations: {
			required: "Required field",
			requiredOption: "Select an option",
			invalidSlugRules: "one or more rules were not met",
			maxValuPerUserLowerThanMinValue:
				"Maximum value per user must be greater than minimum value",
			presetedValueLowerThanMinValue: "Recharge value must be greater than minimum value",
			presetedValueHigherThanMaxValuePerUser:
				"Recharge value must be less than maximum value per user",
			presetedValueAlreadyExists: "Recharge value already exists",
		},
	},
};
