import { vendor } from "./vendor";

export const settings = {
	title: "Editar configurações",
	error: "Sem permissão para editar local",
	attention: "Atenção!",
	menu: {
		title: "Outras opções de configuração",
		general: {
			title: "Gerais",
			subtitle: "Informe limites, e-mails e outros",
		},
		record: {
			title: "Configuração de Fichas",
			subtitle: "Configure o modo ficha",
		},
		couvert: {
			title: "Couvert",
			subtitle: "Estabeleça o valor de couvert",
		},
		features: {
			title: "Funcionalidades",
			subtitle: "Ative as funcionalidades desejadas",
		},
		pdv: {
			title: "PDV",
			subtitle: "Configure o ponto de venda",
		},
		vendor: {
			title: "Vendor",
			subtitle: "Configure informações gerais",
		},
		totem: {
			title: "Totem",
			subtitle: "Personalizar um totem",
		},
		refundReason: {
			title: "Motivos de Estorno/Cancelamento",
			subtitle: "Configure motivos para o PDV",
		},
	},
	warningDialog:
		"Você está habilitando pagamentos pós-pagos em '{{name}}'. " +
		"Você está certo disso? Para confirmar a modificação, " +
		"favor digitar '{{text}}'",
	data: {
		label: "Dados",
		name: "Nome",
		selectProduct: "Selecione um produto para habilitar depósito caução automático",
		noneProduct: "Nenhum produto",
		peopleCapacity: "Capacidade",
		peopleCapacityTooltip: "Quantidade de pessoas que o local comporta.",
	},
	passTemplate: "ID do modelo do passe",
	limits: {
		label: "Limites e serviços",
		postPaidLimit: "Limite de pós-pago",
		serviceTax: "Taxa de serviço",
		rechargeNotIntegratedLimit: "Limite de recarga não integrada",
		nonIntegratedPaymentLimit: "Limite de pagamento não integrado por device e evento",
		enableBuiltInLimit: "Ativar limite de recarga não integrada",
		enableNonIntegratedPaymentLimit:
			"Ativar limite de pagamento não integrado por device e evento",
		nonIntegratedPaymentLimitTooltip:
			"Este limite se refere ao valor máximo de pagamentos não integrados " +
			"que um dispositivo pode receber durante o evento. Esse parâmetro é a soma " +
			"do total de Recargas e Vendas Sem Cartão feitos com pagamentos não integrados.",
		nonIntegratedRechargeLimitTooltip:
			"Este limite se refere ao valor máximo que um funcionário pode fazer em uma recarga não integrada.",
	},
	settings: {
		label: "Configurações",
		myActionsPassword: {
			label: "Senha do ¨Minhas ações¨",
			placeholder: "Insira uma senha numérica ou a senha padrão será 9090",
		},
		sellVisualizationFormat: {
			label: "Formato de visualização dos produtos na venda",
			grid: "Grade",
			list: "Lista",
		},
	},
	everest: {
		label: "Everest",
		user: "Usuário",
		password: "Senha",
		cnpj: "CNPJ",
		host: "Host",
	},
	placeFeatures: "Funcionalidades",
	email: "E-mails para recebimento do fechamento dos eventos",
	emailError: "Email inválido",
	consumption: {
		label: "Configuração fiscal de consumação mínima",
		fiscalProfile: "Perfil fiscal",
		fiscalGroup: "Grupo fiscal",
		ncm: "NCM",
	},
	alert: "Modificações foram realizadas, salve para mantê-las",
	save: "Salvar",
	saveSettings: "Salvar configurações",
	image: "Foto",
	ficha: {
		label: "Ficha",
		text: "Título da Ficha",
		placeholderText: "Insira o título da ficha",
		logo: "Imagem na ficha",
		upload: "Anexar imagem",
		formatError: "Formato não suportado",
		imageFormatMessage: "É necessário que a imagem seja um PNG ou SVG.",
		recommendationMessage:
			"Recomendamos que a imagem atribuída seja o <strong>logo</strong> da empresa na <strong>cor preta</strong>, uma vez que não é possível realizar a impressão de outra cor.",
		dragOrSelectMessage:
			"Arraste a imagem do computador ou selecione a partir de 'Anexar imagem'.",
		logoFormatMessage:
			"É necessário que o <strong>fundo da imagem/logo esteja branco</strong>, para que fique transparente na impressão.",
		dimensionsMessage:
			"As dimensões sugeridas para uma boa resolução são as de <strong>472x472</strong> px.",
	},
	couvertEdit: {
		label: "Editar couverts",
		editCouvert: "Editar couvert",
		male: "masculino",
		female: "feminino",
		men: "Homens",
		women: "Mulheres",
		edit: "Editar",
		add: "Adicionar",
		cancel: "Cancelar",
		columns: {
			public: "Público",
			name: "Nome",
			category: "Categoria",
			price: "Preço",
		},
		error: {
			required: "Esse campo é obrigatório",
			value: "O valor do couvert deve ser menor ou igual ao limite de pós-pago",
		},
	},
	devices: {
		label: "PDVs do local ({{counter}})",
		placeholder: "Adicionar um PDV a esse local",
	},
	errors: {
		invalidName: "Insira um nome válido",
		invalidTip: "Insira um valor de até 25%",
		postPaidLimit: "Insira um valor válido",
		rechargeNotIntegratedLimit: "Valor mínimo de",
		hasConsumptionObligation:
			"Campo obrigatório para configuração de obrigação de consumo",
		minNonIntegratedPaymentLimit: "Valor mínimo de {{value}}",
		tipOptionsValuesRequired: "Os valores das gorjetas são necessários",
		tipOptionLowerValue: 'O "Valor menor" deve ser menor que os valores médio e maior',
		tipOptionHigherValue: 'O "Valor maior" deve ser maior do que o valor médio',
	},
	totem: {
		logoSelectDescription:
			"<strong>Selecione o logo que melhor se aplica na sua imagem</strong> (desconsidere a cor de fundo)",
		addLogo: "Adicionar",
		important: "Importante",
		instructions: "Recomendações",
		image: "A imagem deve ter  as dimensões de <strong>1080 x 1920</strong> px de altura",
		logo:
			"O logo da <strong>Zig</strong> aparecerá sobre a imagem atribuída, centralizado na parte superior",
		recomendations:
			"É recomendado inserir a mensagem <strong>'toque para iniciar'</strong> para orientar o usuário",
		defaultMessage:
			"Caso não adicione uma imagem de tela inicial, " +
			"a tela padrão da Zig será a sua tela inicial " +
			"(vide primeira imagem em “Cores”)",
		totemImgLabel: "Imagem da tela inicial (opcional)",
		totenCustomizationLabel: "Personalização Totem Ficha",
		removeTotemImage: {
			title: "Deseja excluir a tela inicial?",
			content: "Ao excluir, a tela inicial volta a ser a tela padrão da Zig.",
			confirmBtn: "Sim, quero excluir",
			cancelBtn: "Não",
		},
		colors: "Cores",
		saveMessage: "Salvar personalização",
	},
	tip: {
		taxType: "Tipo de taxa",
		tip: "Gorjeta",
		tipSuggestions: "Sugestões de gorjeta",
		serviceTax: "Taxa de serviço",
		none: "Sem taxa",
		lowerValue: "Valor menor",
		medValue: "Valor médio",
		higherValue: "Valor maior",
		tooltip:
			"As recomendações de gorjeta tornam o pagamento de conta mais fácil, " +
			"oferecendo três opções de porcentagens (Menor, Média e Maior) com base " +
			"no valor total da conta, por exemplo: 5%, 10% e 15% limitado a 25%",
	},
	vendor,
	invoiceMoment: {
		fieldLabel: "Selecione o momento para emissão de Fatura cashless",
		tip:
			"Selecione o momento da emissão da fatura para pagamentos Cashless. Para todas as outras formas de pagamento, que não sejam Cashless, a fatura será sempre emitida no momento do consumo.",
		recharge: "Recarga",
		rechargeAndActivation: "Recarga e ativação",
		consumption: "Consumo",
		consumptionAndActivation: "Consumo e ativação",
	},
	refundReason: {
		addReason: "Criar motivo",
		cancel: "Cancelamento",
		modalDelete: {
			confirmation: "Deseja remover da lista de motivos de estornos e cancelamento?",
			description:
				"Ao remover o motivo não aparecerá mais, mas permanecerá nos relatórios antigos.",
			title: "Remover motivo de estorno/cancelamento",
		},
		modalForm: {
			addReason: "Criar Motivo",
			allowOpenText: "Habilitar justificativa",
			creatingReason: "Criando um motivo",
			editReason: "Salvar alterações",
			editingReason: "Editando um motivo",
			reason: "Motivo",
			reasonPlaceholder: "Digite o motivo de estorno e/ou cancelamento",
		},
		reason: "Motivo",
		removeReason: "Editar motivo",
		refund: "Estorno",
		table: {
			cancel: "Cancelamento",
			editReason: "Editar motivo",
			observation: "Observação",
			reason: "Motivo",
			refund: "Estorno",
			removeReason: "Remover motivo",
			status: "Status",
		},
		title: "Motivos de Estorno/Cancelamento",
	},
	defaultDateFilter: "Filtro de data padrão",
};
