export const clientArea = {
	label: "Customer Area",
	warningNotification:
		"Access not allowed. Enable the ¨Customer Area¨ employee permission.",
	places: "Places",
	org: "Organization",
	searchPanel: "Panel name",
	noPanels: "No panels available.",
	noPanelsForFilter: "No panel corresponding to applied filters",
	autoRefresh: "Automatic Update.",
};
