import { Input } from "..";
import * as React from "react";

import ReactInputMask, { Props as IMaskedInputProps } from "react-input-mask";

export function MaskedInput(props: IMaskedInputProps) {
	return (
		<ReactInputMask {...props}>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
			/* @ts-ignore */}
			{inputProps => <Input {...inputProps} />}
		</ReactInputMask>
	);
}
