export const storageAdd = {
	warningProductAlreadyOnList: "This product has already been added to the list!",
	title: "Add product to {{storageName}} stock",
	cancel: "Cancel",
	finish: "Finished",
	addProductForm: {
		form: {
			label: {
				product: "Product",
				count: "Amount",
				cost: "Cost price",
			},
			validation: {
				missingField: "Mandatory field",
				invalidQuantity: "Invalid quantity",
			},
			submit: "Add",
		},
	},
	table: {
		columns: {
			product: "Product",
			count: "Amount",
			unitcost: "Unit cost",
			totalcost: "Total cost",
			action: "action",
		},
	},
};
