import { soldProducts } from "./sold-products";
import { chipsBurned } from "./chips-burned";
import { paymentMethodReport } from "./payment-method-report";

export const barReports = {
	tabs: {
		soldProducts,
		chipsBurned,
		waiterReport: {
			label: "Sales",
			filter: "Filter by transaction type",
		},
		deliveredProductsByBar: {
			label: "Products delivered by bar",
		},
		consolidatedBarReport: {
			label: "Financial per bar",
			tab: "Billing per bar",
			filter: "Filter by transaction type",
			total: "Total",
			sheet: {
				title: "Billing per bar",
				fileName: "billing-per-bar",
				name: "Product",
				quantity: "Quantity",
				totalValue: "Total value",
				totalDiscount: "Total discount",
				value: "Net value",
				totalTax: "Total Tax",
				vat: {
					totalValue: "Total value with VAT",
					valueWithDiscount: "Value with discount",
					value: "Value without VAT",
					totalTax: "VAT amount",
					totalTaxPercentage: "Total VAT percentage (%)",
				},
			},
			paymentMethodReport,
		},
		soldProductReport: {
			label: "General output of products",
			filter: "Filter by transaction type",
			sheet: {
				title: "General output of products",
				fileName: "product_general_output_report",
				fiscalCode: "SKU",
				name: "Product",
				count: "Quantity",
			},
		},
		reprintReport: {
			title: "Reprint of cards",
			filename: "reprint-of-cards",
			byTransaction: "By transaction",
			byProduct: "By product",
			variable: "Variable",
			variableTooltip:
				"The unit value of products with variable value is the average of the values sold",
			table: {
				authorizedBy: "Authorizing employee",
				date: "Date/time",
				employee: "Logged Employee",
				transactionId: "Transaction ID",
				device: "Device",
				product: "Product",
				total: "Total",
				reprintTotal: "Total in reprints",
				reprintProductTotal: "Total reprinted products",
				authorizedByPassword: "Authorized by password",
				quantity: "Quantity",
				productName: "Product name",
				unitValue: "Unit value",
				value: "Value",
			},
			tooltipTitle:
				"This report will only bring data if the POS are at version 5.0.190 or higher.",
		},
	},
	bar: "Bar {{name}}",
	undefinedBar: "Undefined Bar",
	productReturnsReport: {
		title: "Product return",
	},
};
