export const navEN = {
	header: {
		logout: "Logout",
		org: "Organization",
		place: "Place",
		event: "Event",
		language: "Language",
	},
	navbar: {
		default: {
			logout: "Logout",
			changeLanguage: "Change Language",
			changeSystemLanguage: "Change System Language",
		},
		selectLanguageModal: {
			selectLanguage: "Select the language",
			cancel: "Cancel",
			confirm: "Confirm",
		},
		org: {
			panel: "Places",
			employee: "Team Access",
			crm: "CRM",
			categories: "Categories",
			reports: "Reports",
			recharge: "Recharge",
			stock: "Stock",
			giftback: "Giftback",
			fiscal: "Fiscal",
			taxRegistration: "Tax Registration",
			productRegistration: "Product Registration",
			advancedBI: "Advanced BI",
			customersList: "Customer List",
			stockInventory: "Product Base Registration",
			taxData: "Tax Data",
			fiscalSettings: "Fiscal Data",
			financialReport: "Reports",
			satisfactionSurvey: "Satisfaction Survey",
			customers: "Customer's Area",
			sendingMessages: "Sending Messages",
			loyalty: "Loyalty Programs",
			communication: "Communication Campaigns",
			chartOfAccounts: "Chart of Accounts",
			fiscalBoard: "Fiscal Board",
			vendors: "Vendors",
			team: "Team",
		},
		place: {
			events: "Events",
			businessInteligence: "Business Intelligence",
			products: "Products",
			menu: "Menu",
			bars: "Bar",
			opened: "Opened",
			closed: "Closed",
			crm: "CRM",
			reports: "Reports",
			preChargeLabel: "Pre-charge",
			inventoryControl: "Inventory control",
			receptive: "Receptive",
			productAndMenu: "Product & Menu",
			clients: "Customers",
			tagGroups: "Tag Groups",
			reserve: "Reservation and Queue",
			tickets: "Tickets",
			guestList: "Guest List",
			financialReport: "Financial Reports",
			barReports: "Bar Reports",
			tableReports: "Table Reports",
			prechargeReports: "Top-up Reports",
			ticketsReport: "Ticket Reports",
			storage: "Storage",
			extract: "Extract",
			invoices: "Invoices",
			promotions: "Promotions and Discounts",
			pos: "POS Sales",
			cashFlow: "Financial",
			zigAccount: "Zig Account",
			delivery: "Delivery",
			transferReport: "Transfer Report",
			multivendor: "Multi-vendor",
			precharge: "Precharge",
			whatsup: "What's Up?",
			passwordCard: "Password card",
			audits: "Audits",
			settings: "Settings",
			contracts: "Contracts",
			rechargeManagement: "Balance Management",
			generalConfig: "General Configuration",
			operationReport: "Operation Report",
		},
		event: {
			panel: "Panel",
			eventPanel: "Event Panel",
			reports: "Reports",
			customers: "Customers",
			clients: "Customers Information",
			conference: "Event Conference",
			guestList: "Guest Lists",
			financialReport: "Financial Reports",
			barReports: "Bar Reports",
			tableReports: "Table Reports",
			ticketsReport: "Ticket Reports",
			sellChip: "Card-free Sales",
			bookings: "Bookings",
			devices: "Devices",
			multivendor: "Multi-vendor",
			zigTag: "Zig Tag",
			operationReport: "Operation Report",
			hawkersReport: "Hawkers Report",
			rechargeManagement: "Balance Management",
		},
	},
};
